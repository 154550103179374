import React, { useRef, useState, useContext, createContext, useLayoutEffect } from "react";
import FloatLabel from "../float-label/Index";
import { useFloating, autoUpdate, size, flip, offset } from "@floating-ui/react-dom";
import "./index.css";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import { Space, Button, Typography } from "antd";
import { CloseCircleOutlined, DownOutlined, UpOutlined, SearchOutlined } from "@ant-design/icons";
import { Virtuoso } from 'react-virtuoso'
import { createPortal } from "react-dom";

const SelectContext = createContext({});

export function usePrevious(value) {
  const ref = useRef();
  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const Option = ({ dropdownId, index, label, value }) => {
  const { listRef, activeIndex, handleClickOption } = useContext(SelectContext);

  return (
    <div
      tabIndex={-1}
      id={value}
      className={`${dropdownId} Option ${activeIndex === index ? "OptionActive" : ""}`}
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      onClick={() => handleClickOption(value)}
    >
      {label}
    </div>
  );
};

const CustomSelect = ({
  id,
  formElementId,
  form,
  validateImmediately,
  bordered = true,
  openMenuOnFocus = true,
  options = [],
  onChange,
  value,
  uppercase = true,
  onNext,
  onKeyDown,
  onBlur,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  disabled,
  required,
  allowNewOption,
  allowEditOption,
  handleClickNewOption,
  handleClickEditOption,
  forwardedRef,
  focusedOptionDetailsRender,
  defaultActiveFirstOption = true,
  defaultActiveOptionValue,
  onSelect,
  showSearch,
  filterFunc,
  allowClear,
  autoComplete,
  autoFocus,
  style,
  selectedOptionLabelRender
}) => {
  const dropdownIdRef = useRef(nanoid());
  const selectedIndex = options.findIndex((obj) => obj.value === value);
  const selectedOptionLabel = selectedIndex !== -1 ? options[selectedIndex]?.label : null;
  const finalSelectedOptionLabel = selectedOptionLabelRender ? selectedOptionLabelRender() : selectedOptionLabel;
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = forwardedRef || useRef();
  const virtuosoRef = useRef();

  let defaultActiveIndex = -1;
  if (value === undefined || value === null) {
    if (defaultActiveOptionValue) {
      const i = options.findIndex((obj) => obj.value === defaultActiveOptionValue);
      if (i > -1) {
        defaultActiveIndex = i;
      }
    } else if (defaultActiveFirstOption) {
      defaultActiveIndex = 0;
    }
  }

  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const upperCasedSearchValue = searchValue?.toUpperCase();
  const filteredOptions = searchValue
    ? options
      .filter(filterFunc ? (obj) => filterFunc(obj, upperCasedSearchValue) : (obj) => obj.label.toUpperCase().includes(upperCasedSearchValue))
      .sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (
          labelA.startsWith(upperCasedSearchValue) !== labelB.startsWith(upperCasedSearchValue)
        ) {
          if (
            labelA.startsWith(upperCasedSearchValue) &&
            !labelB.startsWith(upperCasedSearchValue)
          ) {
            return -1;
          }
          return 1;
        }
        return labelA < labelB ? -1 : 1;
      })
    : options;

  const listItemsRef = useRef([]);

  useEffect(() => {
    if (selectedIndex !== -1) {
      const selectedValue = options[selectedIndex].value;
      const activeIndex = filteredOptions.findIndex((obj) => obj.value === selectedValue);
      setActiveIndex(activeIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if ((value === null || value === undefined) && defaultActiveIndex !== -1) {
      setActiveIndex(defaultActiveIndex);
    }
  }, [defaultActiveIndex]);

  useEffect(() => {
    if (!open) {
      return;
    }
    function focusInClickListener(e) {
      if (!e.target.className.includes?.(dropdownIdRef.current)) {
        setOpen(false);
      }
    }
    window.addEventListener('focusin', focusInClickListener);
    return () => {
      window.removeEventListener("focusin", focusInClickListener);
    };
  }, [open]);

  const { x, y, refs, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 8 }),
      size({
        apply({ rects, elements }) {
          if (rects.reference.width) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          }
        },
      }),
    ],
  });

  function handleFocus() {
    if (openMenuOnFocus) {
      setOpen(true);
    }
  }

  useEffect(() => {
    if (!open) {
      setActiveIndex(Math.max(selectedIndex, defaultActiveIndex));
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      virtuosoRef.current?.scrollIntoView({ index: activeIndex });
    }
  }, [open, activeIndex]);

  const changeSelectedValue = (newValue) => {
    if (value !== newValue && onChange) {
      onChange(newValue);
    }
    if (onSelect) {
      onSelect(newValue);
    }
    setSearchValue("");
    setOpen(false);
  };

  const handleInputKeyDown = async (e) => {
    const key = e.key;

    if (key?.toUpperCase() === "C" && (e.ctrlKey || e.metaKey)) {
      navigator.clipboard.writeText(String(finalSelectedOptionLabel));
    }
    switch (key) {
      case "F3": {
        if (allowNewOption) {
          e.stopPropagation();
          e.preventDefault();
          handleClickNewOption();
        }
        break;
      }
      case "F4": {
        if (allowEditOption) {
          e.stopPropagation();
          e.preventDefault();
          handleClickEditOption(filteredOptions[activeIndex]?.value);
        }
        break;
      }
      case "Tab": {
        setOpen(false);
        break;
      }
      case "Enter": {
        changeSelectedValue(filteredOptions[activeIndex]?.value);
        if (validateImmediately && form && (formElementId || id)) {
          try {
            await form.validateFields([formElementId || id]);
          } catch (error) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }
        break;
      }
      case "Delete": {
        e.stopPropagation();
        setSearchValue("");
        break;
      }
      case "Backspace": {
        e.stopPropagation();
        break;
      }
      case "ArrowDown": {
        if (activeIndex < filteredOptions.length - 1) {
          setActiveIndex(activeIndex + 1);
        }
        break;
      }
      case "ArrowUp": {
        if (activeIndex > 0) {
          e.preventDefault();
          e.stopPropagation();
          setActiveIndex(activeIndex - 1);
        }
        break;
      }
      case "0": {
        if (!e.target.value) {
          e.preventDefault();
          setTimeout(() => setOpen(false), 100);
        }
        break;
      }
    }

    if (!open && !["Enter", "Tab", "0", "Ctrl", "Control", "Alt", "F2", "F3", "F4", "ArrowLeft", "ArrowRight"].includes(key) && !e.ctrlKey) {
      setOpen(true);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }

    if (key === "Enter" || (key === "Tab" && !e.shiftKey)) {
      if (onNext) {
        onNext(e);
      }
    }
  };

  const handleChangeInput = (e) => {
    setSearchValue(uppercase ? e.target.value.toUpperCase() : e.target.value);
    setActiveIndex(0);
  };

  const handleClickOption = (value) => {
    inputRef.current?.focus();
    changeSelectedValue(value);
  };

  const handleClickInput = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClickClear = () => {
    changeSelectedValue(null);
    setSearchValue("");
  };

  const showClearIcon = allowClear && hover && (searchValue || value);

  let rightIcon = null;
  if (!showClearIcon) {
    if (!open) {
      rightIcon = <DownOutlined style={{ color: "#bbb", fontSize: 12 }} onClick={(e) => { e.stopPropagation(); setOpen(true) }} />;
    } else if (showSearch) {
      rightIcon = <SearchOutlined style={{ color: "#bbb", fontSize: 12 }} />;
    } else {
      rightIcon = <UpOutlined style={{ color: "#bbb", fontSize: 12 }} onClick={(e) => { e.stopPropagation(); setOpen(false); }} />
    }
  }

  const initialTopMostItemIndex = Math.max(selectedIndex >= 3 ? selectedIndex - 3 : defaultActiveIndex, defaultActiveIndex, 0);
  const virtuosoHeight = Math.min(filteredOptions.length * 34, 300);
  return (
    <FloatLabel label={placeholder} required={required}>
      <SelectContext.Provider
        value={{
          activeIndex,
          listRef: listItemsRef,
          handleClickOption,
        }}
      >
        <div
          className={`Select ${showSearch ? "SelectSearch" : ""}
          ${bordered ? " SelectBordered" : ""}
          ${readOnly ? " SelectReadOnly" : ""}`}
          style={style}
          ref={refs.setReference}
          onClick={() => {
            inputRef.current?.focus();
            setOpen(true);
          }}
          onMouseEnter={allowClear ? () => setHover(true) : null}
          onMouseLeave={allowClear ? () => setHover(false) : null}
        >
          {!open && (
            <Typography.Text
              ellipsis
              style={{ zIndex: 2, margin: 0, width: "100%", textAlign: "left" }}
            >
              {finalSelectedOptionLabel}
            </Typography.Text>
          )}
          <input
            id={id}
            className={`${dropdownIdRef.current} select ant-input custom-input ant-input-borderless
            ${!showSearch ? " hide-cursor" : ""}`}
            placeholder={open ? selectedOptionLabel : ""}
            disabled={disabled}
            tabIndex={readOnly || skipFocus ? -1 : tabIndex}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            value={open ? searchValue : ""}
            readOnly={readOnly}
            onChange={!readOnly ? handleChangeInput : null}
            ref={inputRef}
            onFocus={handleFocus}
            onKeyDown={handleInputKeyDown}
            onClick={handleClickInput}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
          />
          {showClearIcon && (
            <CloseCircleOutlined
              onClick={handleClickClear}
              style={{
                color: "#999",
                fontSize: 12,
                right: 8,
                cursor: "pointer",
                zIndex: 2
              }}
            />
          )}
          {rightIcon}
        </div>
        {!disabled && !readOnly && open && (
          <>
            {createPortal(
              <div
                className="SelectDropDown"
                tabIndex={-1}
                ref={refs.setFloating}
                style={{
                  position: strategy,
                  top: y ?? "",
                  left: x ?? "",
                  minWidth: allowNewOption ? 160 : undefined
                }}
              >
                <div className="SelectOptionsContainer">
                  {filteredOptions.length > 0 ? (
                    <Virtuoso
                      ref={virtuosoRef}
                      style={{ height: virtuosoHeight }}
                      totalCount={filteredOptions.length}
                      data={filteredOptions}
                      initialTopMostItemIndex={initialTopMostItemIndex}
                      itemContent={(index, data) => <Option
                        key={data?.value}
                        value={data?.value}
                        label={data?.label}
                        index={index}
                        dropdownId={dropdownIdRef.current}
                      />}
                    />
                  ) : (
                    (
                      <div
                        style={{
                          padding: 16,
                          height: 150,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ color: "grey" }}>No Data...</span>
                      </div>
                    )
                  )}
                </div>
                {focusedOptionDetailsRender && (
                  <>
                    <div style={{ borderTop: "1px solid lightgrey" }} />
                    {focusedOptionDetailsRender(filteredOptions[activeIndex]?.value)}
                  </>
                )}
                {(allowNewOption || allowEditOption) && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "white",
                        padding: 8,
                        borderTop: "1px solid lightgrey",
                      }}
                    >
                      <Space>
                        {allowNewOption && (
                          <Button
                            tabIndex={-1}
                            type="primary"
                            size="small"
                            onMouseDown={() => {
                              setOpen(false);
                              setTimeout(() => handleClickNewOption?.(), 100);
                            }}
                          >
                            New (F3)
                          </Button>
                        )}
                        {allowEditOption && (
                          <Button
                            tabIndex={-1}
                            size="small"
                            onMouseDown={() => {
                              setOpen(false);
                              setTimeout(
                                () => handleClickEditOption?.(filteredOptions[activeIndex]?.value),
                                100
                              );
                            }}
                          >
                            Edit (F4)
                          </Button>
                        )}
                      </Space>
                    </div>
                  </>
                )}
              </div>,
              document.body
            )}
          </>
        )}
      </SelectContext.Provider>
    </FloatLabel>
  );
};

export default CustomSelect;
